import { FeatureData } from '@innedit/innedit';
import { DocumentType, ProduitType, SlotType } from '@innedit/innedit-type';
import dayjs, { Dayjs } from 'dayjs';
import React, { FC, useEffect, useState } from 'react';

import Button from '../../../components/Button';
import Calendar from '../../../components/Calendar';
import { calcEnd } from '../../../components/Slot';
import Slots from '../../../components/Slots';

const ProductWithSlots: FC<{
  produit: DocumentType<ProduitType>;
}> = ({ produit }) => {
  const [selected, setSelected] = useState<Dayjs>();
  const [slots, setSlots] = useState<DocumentType<SlotType>[]>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    const featureData = new FeatureData<SlotType>({
      collectionName: `produits/${produit.id}/slots`,
      espaceId: produit.boutique,
    });

    featureData
      .find()
      .then(setSlots)
      .catch(e => setError(e.message));
  }, [produit.id]);

  const handleOnSelect = (date: string) => {
    const day = dayjs(date);
    setSelected(day);
  };

  if (error) {
    return <div>{error}</div>;
  }

  const selectedSlots =
    selected &&
    slots?.filter(({ date, day, isRecurring }) =>
      isRecurring && day
        ? parseInt(day, 10) === selected.weekday()
        : selected.isSame(dayjs(date)),
    );

  return (
    <div className="flex space-x-12 justify-between">
      {slots ? (
        <div>
          <Slots
            datas={slots}
            espaceId={produit.boutique}
            produitId={produit.id}
          />
        </div>
      ) : (
        <div>Chargement en cours des créneaux</div>
      )}
      <div>
        <Calendar onSelect={handleOnSelect} selected={selected} slots={slots} />
        {selected && (
          <div>
            Créneaux du {selected.format('dddd D MMM YYYY')} :
            {selectedSlots &&
              selectedSlots.length > 0 &&
              selectedSlots.map(({ id, start, duration }) => (
                <div key={id}>
                  {start && `[${start}-${calcEnd(start, duration)}]`}
                  <Button
                    to={`/${produit.boutique}/${
                      produit.id
                    }/${id}/${selected.format('YYYY-MM-DD')}/`}
                    variant="link"
                  >
                    Réserver
                  </Button>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductWithSlots;
